import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose, withTranslation, getContext } from '@hocs'
import { detectHttp, imageUrl, openPost, publicUrl } from '@libs/appUrl'
import FollowBtn from './FollowBtn'
import styles from './NotifyItem.scss'
import 'moment/locale/zh-tw'
import {
  trackNotificationPrivateEnterEvent,
  trackNotificationPublicEnterEvent,
} from '@libs/eventTracking/notification'
import qs from 'qs'

class NotifyItem extends PureComponent {
  _onClickOpenUserLink = (link) => () => {
    openPost(link, this.props.openTargetBehavior)
  }

  _onClickOpenLink = (url, item) => () => {
    const type = item.get('type')
    const isSystemNotify = type === 'official'
    let newUrl = url

    // 當 Post Id 為 undefined 時 代表此連結不是文章頁連結
    const getPostId = () => {
      if (item.get('postID')) return item.get('postID')
      // 判斷是否為文章頁URL
      const pramList = item.get('url').split('?')[0].split('/')
      const isIncludeTopic = pramList.includes('topic')
      const isIncludePopBox = pramList.includes('popbox')
      if (pramList.length <= 3 && !isIncludeTopic && !isIncludePopBox) {
        return pramList.join('.')
      }
      return undefined
    }

    const eventTrackFunc = isSystemNotify
      ? trackNotificationPublicEnterEvent
      : trackNotificationPrivateEnterEvent

    let payload = {
      currentPage: {
        pageName: 'notification',
        pageUrl: window.location.href,
        pageComponent: isSystemNotify ? 'public' : 'private',
      },
      toPage: {
        pageUrl: url,
      },
    }

    // 當為文章頁連結時，多帶參數到 mixpanel
    if (getPostId()) {
      const queryString = qs.stringify({
        ...qs.parse(url.split('?')[1]),
        fromPage: {
          pageName: 'notification',
          pageComponent: isSystemNotify ? 'public' : 'private',
        },
      })
      newUrl = `${url}?${queryString}`
      payload = Object.assign(payload, {
        toPage: {
          pageUrl: url,
          pageName: 'article',
        },
        actionTarget: getPostId(),
      })
    }

    openPost(newUrl, this.props.openTargetBehavior)
    eventTrackFunc(payload)
  }

  _onClickFollow = (followID, followed) => () => {
    const { updateFollowState } = this.props
    updateFollowState({ followID, follow: !followed })
  }

  _onClickOpenNotify = (id) => (e) => {
    if (e.currentTarget?.getAttribute('check') === -1) {
      const { readNotify } = this.props
      readNotify({ id })
    }
  }

  render() {
    const { item, t } = this.props
    const userImg = imageUrl(item.getIn(['userInfo', 'img']) || '/img/notice/like.png')
    const id = item.get('id')
    const type = item.get('type')
    let link, title
    let reply = item.get('reply')
    let userImgLink =
      item.getIn(['userInfo', 'mid']) && publicUrl(`/user/${item.getIn(['userInfo', 'mid'])}`)
    let notifyImg = item.get('img') && imageUrl('/' + item.get('img'))
    let postType = 'post'

    switch (type) {
      case 'follow':
        link = userImgLink
        break
      case 'oneSignal':
        link = publicUrl(item.get('url'))
        userImgLink = link
        title = item.get('title')
        break
      default: {
        // 如果是 http 開頭
        // 就不是 postID 不用 replace . 成 /
        const maybePostID = item.get('postID')
        link = detectHttp(maybePostID) ? maybePostID : publicUrl(maybePostID.replace(/\./g, '/'))

        break
      }
    }

    switch (item.get('postType')) {
      case 'topic':
      case 'trend':
      case 'place':
        postType = item.get('postType')
        break
    }

    if (reply) {
      if (/^([a-zA-Z0-9]|\/|-)+.(gif|jpg|jpeg|tiff|png)$/i.test(reply)) {
        notifyImg = imageUrl('/' + reply)
        reply = t('notify:text', { context: 'image' })
      }
    }

    return (
      <div
        className="item"
        type={type}
        check={item.get('check')}
        onClick={this._onClickOpenNotify(id)}
      >
        <style jsx>{styles}</style>
        <div className="userImg">
          <img src={userImg} onClick={userImgLink && this._onClickOpenUserLink(userImgLink)} />
        </div>
        <div className="content" onClick={this._onClickOpenLink(link, item)}>
          {title && <span className="title">{title}</span>}
          <span className="text">
            {[
              'follow',
              'like',
              'replyLike',
              'saved',
              'reply',
              'replyb',
              'replyImg',
              'replybImg',
            ].indexOf(type) < 0
              ? item.get('text')
              : t('notify:text', {
                  context: type,
                  name: item.getIn(['userInfo', 'name']),
                  title: item.get('title'),
                  reply,
                  postType: t('notify:type', { context: postType }),
                })}
          </span>
          <span className="date">{moment(item.get('t')).fromNow()}</span>
        </div>
        {type === 'follow' ? (
          <FollowBtn
            follow={item}
            onClick={this._onClickFollow(item.get('userInfo').get('mid'), item.get('isFollowed'))}
          />
        ) : (
          notifyImg && (
            <div className="notifyImg">
              <img src={notifyImg} onClick={this._onClickOpenLink(link, item)} />
            </div>
          )
        )}
      </div>
    )
  }
}

export default compose(
  withTranslation(['notify']),
  getContext({
    updateFollowState: PropTypes.func.isRequired,
    readNotify: PropTypes.func.isRequired,
    openTargetBehavior: PropTypes.string,
  }),
)(NotifyItem)
