import css from 'styled-jsx/css';

export default css`.popapp-loading {
  display: inline-block;
  width: 100%;
  min-height: 50px;
  text-align: center;
  margin-bottom: 50px;
  margin-bottom: calc(100px + constant(safe-area-inset-bottom));
  margin-bottom: calc(100px + env(safe-area-inset-bottom)); }

.popapp-loading-type1 i {
  width: 30px;
  height: 30px;
  margin: 10px 0;
  display: inline-block;
  border-radius: 50%;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }
  .popapp-loading-type1 i:nth-child(1) {
    background: #8d6be7;
    transform: translate(-50px, 0);
    animation-name: popappLoadingAnimate1; }
  .popapp-loading-type1 i:nth-child(2) {
    background: #838eff;
    transform: translate(50px, 0);
    animation-name: popappLoadingAnimate2; }

@keyframes popappLoadingAnimate1 {
  25% {
    z-index: 2; }
  50% {
    transform: translate(50px, 0) scale(1); }
  75% {
    transform: translate(0, 0) scale(0.75); }
  100% {
    transform: translate(-50px, 0) scale(1); } }

@keyframes popappLoadingAnimate2 {
  25% {
    transform: translate(0, 0) scale(0.75); }
  50% {
    transform: translate(-50px, 0) scale(1); }
  75% {
    z-index: 2; }
  100% {
    transform: translate(50px, 0) scale(1); } }
`