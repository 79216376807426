// https://www.figma.com/file/kg1wCHa2ZWS7mneaXyqyKc/Web-Components-2.0?node-id=44%3A532
import { FC, ElementType, MouseEventHandler } from 'react'
import tw, { styled } from 'twin.macro'

export enum ChipVariant {
  enabled = 'enabled',
  activated = 'activated',
  default = 'default',
  primary = 'primary',
}

type Props = {
  variant?: ChipVariant
  as?: ElementType
  onClick?: MouseEventHandler
}

const baseStyle = tw`rounded-md px-3 py-2 text-btn-d leading-btn-d font-medium`
const focusStyle = tw`focus:outline-none`
const defaultStyle = tw`bg-gray-30 text-gray-800 hover:opacity-60`
const primaryStyle = tw`bg-primary-light text-primary-normal`
const enabledStyle = tw`bg-gray-30 text-gray-200 active:bg-primary-normal active:text-white`
const activatedStyle = tw`bg-primary-normal text-white`

// TODO: manually add typings here. `styled-components`'s types seem broken; component type it returned is `any`
const Chip = styled.button<Props>(({ variant = ChipVariant.enabled }) => [
  baseStyle,
  focusStyle,
  variant === ChipVariant.default && defaultStyle,
  variant === ChipVariant.primary && primaryStyle,
  variant === ChipVariant.enabled && enabledStyle,
  variant === ChipVariant.activated && activatedStyle,
])

export default Chip
export const TypeOverwriteForStorybook: FC<Props> = Chip
