import css from 'styled-jsx/css';

export default css`.btn {
  min-width: 5em;
  white-space: nowrap;
  text-align: center;
  padding: .8em 1em;
  border: 1px solid;
  border-radius: 5px;
  font-size: .8em; }
  .btn[data-followed="0"] {
    border-color: #838eff;
    background-color: #838eff;
    color: #fff; }
  .btn[data-followed="1"] {
    border-color: #6d6d6d;
    color: #6d6d6d; }
`