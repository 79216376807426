import { getEventTracker } from '@libs/eventTracking'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'

/**
 * [Link to spec](https://www.dropbox.com/scl/fi/nltrxf9z7z90cisb29zs6/%E3%80%90%E6%95%B8%E6%93%9A%E3%80%91Web-%E5%B0%8E%E8%A6%BD%E5%88%97-v3.0.paper?dl=0&rlkey=7nqkwfkileg73x0gna347qmhv#:uid=026835857265077615568832&h2=sprint-41)
 */
export function trackWebHeaderEnterPopselect(input) {
  const { pageName, pageUrl } = input.currentPage
  getEventTracker().track({
    name: WebEventNames.WEB_HEADER_ENTER_POPSELECT,
    properties: {
      currentPage: getCurrentPagePropertyFor(pageName, pageUrl),
      toPage: getNextPagePropertyFor(input.toPage),
    },
  })
}
