import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import Transition from '@components/Transition'

export enum PopperSize {
  medium = 'medium',
  small = 'small',
  default = 'default',
}

type PopperProps = {
  open: boolean
  children: React.ReactNode
  fixedHeader?: boolean
  duration?: number
  size?: PopperSize
}
type PopperRef = React.RefObject<HTMLInputElement>

const Popper = (props: PopperProps, ref: PopperRef) => {
  const { open = false, fixedHeader = false, duration = 150, size = 'default', children } = props
  return (
    <Transition open={open} transitionDuration={duration}>
      {({ isTransition, transitionDuration }) => (
        <Shape
          isTransition={isTransition}
          fixedHeader={fixedHeader}
          duration={transitionDuration}
          ref={ref}
        >
          <Container size={size}>{children}</Container>
        </Shape>
      )}
    </Transition>
  )
}
export default React.memo(React.forwardRef(Popper))
export const TypeOverwriteForStorybook: FC<PopperProps> = Popper
const smallSizeStyle = css`
  width: 200px;
  height: auto;
`
const mediumSizeStyle = css`
  width: 360px;
  min-height: 428px;
  max-height: 500px;
`
const defaultStyle = css`
  width: 200px;
  height: 200px;
`

const Shape = styled.div<{
  fixedHeader: boolean
  isTransition: boolean
  duration: number
}>`
  left: 50%;
  transform: translate(-50%, 100%);
  transition-duration: ${({ duration }) => `${duration}ms`};
  ${tw`absolute z-mask`}
  ${({ isTransition }) =>
    isTransition ? `opacity: 1; bottom: -13px` : `opacity: 0; bottom: -15px `};
  ${({ fixedHeader }) =>
    fixedHeader
      ? `
      ${PopperHeader} {
        position: absolute;
      }
      ${PopperContent} {
        padding-top: 48px;
      }
  `
      : ''}
  &:after {
    content: '';
    border-style: solid;
    border-width: 0 7px 12.1px 7px;
    border-color: transparent transparent #fff transparent;
    top: -12px;
    left: 50%;
    margin-left: -6px;
    ${tw`w-0 h-0 absolute z-20`}
  }
`

const Container = styled.div<{
  size: string
}>`
  ${({ size }) => [
    size === PopperSize.small && smallSizeStyle,
    size === PopperSize.medium && mediumSizeStyle,
    size === PopperSize.default && defaultStyle,
  ]}
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
  ${tw`rounded box-border overflow-y-auto`}
`

export const PopperHeader = styled.div`
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  ${tw`rounded-t z-10 w-full h-12 overflow-hidden`}
`

export const PopperContent = styled.div`
  ${tw`text-h6-d box-border`}
`
