import categories from './categories.json'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const events = []

if (publicRuntimeConfig['2022whiteday']) {
  events.push('2022whiteday')
}

if (publicRuntimeConfig['2022valentines']) {
  events.push('2022valentines')
}

if (publicRuntimeConfig['2022newyear']) {
  events.push('2022newyear')
}

if (publicRuntimeConfig['2021popshopping']) {
  events.push('2021popshopping')
}

if (publicRuntimeConfig['2021popanniversary']) {
  events.push('2021popanniversary')
}

if (publicRuntimeConfig['2021popxmas']) {
  events.push('2021popxmas')
}

export default { categories, events }
