import { styled } from 'twin.macro'
import Chip from '@components/DesignSystem/Chip'
import { eventCategoryStyle } from './Navigation.styled'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  padding: 0 18px;
`

export const TitleBar = styled.div`
  width: 100%;
  height: 44px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  line-height: 44px;
`

export const CategoryLink = styled.a`
  color: #999999;
  font-weight: normal;
  margin-right: 8px;
  margin-bottom: 12px;
  display: inline-block;

  ${eventCategoryStyle};
`

export const ChipBtn = styled(Chip)`
  color: inherit;
  font-weight: inherit;
`
