import { getEventTracker } from '@libs/eventTracking'
import { paths } from '@routes/header-paths'
import {
  getNextPagePropertyFor,
  getCurrentPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/tracking_events/events/web-header/web-header.enter-register-login.md)
 */
export function trackHeaderEnterRegisterLoginEvent() {
  getEventTracker().track({
    name: WebEventNames.WEB_HEADER_ENTER_REGISTER_LOGIN,
    properties: {
      currentPage: getCurrentPagePropertyFor(''),
      toPage: getNextPagePropertyFor({
        pageName: 'web-register-login',
        pageUrl: window.location.origin + paths.login,
      }),
    },
  })
}
