import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  SyntheticEvent,
  useCallback,
  ReactNode,
} from 'react'
import { theme } from 'twin.macro'
import Popper, { PopperContent, PopperSize } from '@components/Popper'

import dynamic from 'next/dynamic'
const Drawer = dynamic(() => import('@components/Drawer'), { ssr: false })

import {
  DropdownItem,
  DropdownWrapper,
  DropdownItemTitle,
  DropdownItemChevronIconRight,
  DropdownItemChevronIconTop,
  DropdownItemChevronIconDown,
  DropdownContent,
  DropdownSubMenu,
} from './Dropdown.styled'
import useMenus, { MenuItem } from './useMenus'
import UserItem, { UserInfo, UserStatus } from './UserItem'
export { UserStatus }

export enum ScreenSize {
  mobile = 'mobile',
  desktop = 'desktop',
}

type MenuItemClick = (item: MenuItem) => (event: React.FormEvent<HTMLBaseElement>) => void

type DropdownProps = {
  data: MenuItem[][]
  handleItemClick: MenuItemClick
  screenSize: string
}

const HeaderMenuContext = React.createContext<{
  handleItemClick?: MenuItemClick
  screenSize?: string
}>({})

const createDesktopListWrapper = (items: MenuItem[], handleItemClick, index: number) => {
  return (
    <DropdownWrapper key={index}>
      {items.map((item) => {
        if (item.customComponent) {
          return React.createElement(item.customComponent.target, item.customComponent.props)
        }
        return (
          <DropdownItem
            key={item.name}
            onMouseOver={handleItemClick(item)}
            onClick={() => (item.event ? item.event() : '')}
          >
            <a
              {...(item?.href ? { href: item.href } : {})}
              {...(item?.newTab ? { target: '_blank' } : {})}
            >
              <DropdownItemTitle active={!!item.active}>
                {item?.icon && React.createElement(item.icon, { className: 'icon' })}
                <p>{item.name}</p>
                {item.sub && <DropdownItemChevronIconRight />}
              </DropdownItemTitle>
            </a>
          </DropdownItem>
        )
      })}
    </DropdownWrapper>
  )
}
type MobileDropdownItemProps = {
  children: (isCollapse: boolean) => React.ReactNode
  subItemsNumber: number
  onClick: () => void
}
const MobileDropdownItem = (props: MobileDropdownItemProps) => {
  const [isCollapse, setIsCollapse] = useState(false)
  const { children, subItemsNumber, onClick } = props
  return (
    <DropdownItem
      style={{
        height: isCollapse ? `${60 + subItemsNumber * 60}px` : '60px',
      }}
      onClick={() => {
        if (onClick) onClick()
        if (subItemsNumber !== 0) setIsCollapse(!isCollapse)
      }}
    >
      {children(isCollapse)}
    </DropdownItem>
  )
}
const createMobileListWrapper = (items: MenuItem[], index: number) => {
  return (
    <DropdownWrapper key={index}>
      {items.map((item, index) => {
        if (item.customComponent) {
          return React.createElement(item.customComponent.target, item.customComponent.props)
        }
        return (
          <MobileDropdownItem
            key={item.name}
            subItemsNumber={item?.sub?.length ?? 0}
            onClick={() => (item.event ? item.event() : '')}
          >
            {(isCollapse) => (
              <>
                <a
                  {...(item?.href ? { href: item.href } : {})}
                  {...(item?.newTab ? { target: '_blank' } : {})}
                >
                  <DropdownItemTitle active={!!item.active}>
                    {item?.icon && React.createElement(item.icon, { className: 'icon' })}
                    <p>{item.name}</p>
                    {item.sub && isCollapse && <DropdownItemChevronIconTop />}
                    {item.sub && !isCollapse && <DropdownItemChevronIconDown />}
                  </DropdownItemTitle>
                </a>
                {item.sub && createMobileListWrapper(item.sub, index)}
              </>
            )}
          </MobileDropdownItem>
        )
      })}
    </DropdownWrapper>
  )
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { data, handleItemClick, screenSize } = props
  const menus = useMemo(() => {
    const result = data.reduce((list: ReactNode[], items, index) => {
      if (Array.isArray(items)) {
        const menu =
          screenSize === ScreenSize.desktop
            ? createDesktopListWrapper(items, handleItemClick, index)
            : createMobileListWrapper(items, index)
        list.push(menu)
      }
      return list
    }, [])
    return result
  }, [data, handleItemClick, screenSize])
  return <DropdownContent>{menus}</DropdownContent>
}

const GuestMenu = () => {
  const { screenSize, handleItemClick } = React.useContext(HeaderMenuContext)
  const { InfoItems, AreaItems, OpenPopDailyAppItems } = useMenus()
  const OtherItems = screenSize === ScreenSize.desktop ? [] : [...OpenPopDailyAppItems]
  return (
    <Dropdown
      data={[InfoItems, AreaItems, OtherItems]}
      screenSize={screenSize}
      handleItemClick={handleItemClick}
    />
  )
}

const MemberMenu = () => {
  const { screenSize, handleItemClick } = React.useContext(HeaderMenuContext)
  const {
    UserItems,
    MemberDesktopItems,
    MemberMobileItems,
    InfoItems,
    AreaItems,
    SignOutItems,
    OpenPopDailyAppItems,
  } = useMenus()
  const data =
    screenSize === ScreenSize.desktop
      ? [UserItems, MemberDesktopItems, InfoItems, AreaItems, SignOutItems]
      : [MemberMobileItems, InfoItems, AreaItems, [...SignOutItems, ...OpenPopDailyAppItems]]

  return <Dropdown data={data} screenSize={screenSize} handleItemClick={handleItemClick} />
}

const CreatorMenu = () => {
  const { screenSize, handleItemClick } = React.useContext(HeaderMenuContext)
  const {
    CreatorItems,
    InfoItems,
    AreaItems,
    SignOutItems,
    OpenPopDailyAppItems,
    UserItems,
  } = useMenus()
  const data =
    screenSize === ScreenSize.desktop
      ? [UserItems, CreatorItems, InfoItems, AreaItems, SignOutItems]
      : [CreatorItems, InfoItems, AreaItems, [...SignOutItems, ...OpenPopDailyAppItems]]
  return <Dropdown data={data} screenSize={screenSize} handleItemClick={handleItemClick} />
}

type HeaderMenuProps = {
  userStatus: UserStatus
  open: boolean
  handleClose: (event: SyntheticEvent) => void
  userInfo?: UserInfo
}

const HeaderMenu: React.FC<HeaderMenuProps> = (props) => {
  const { userStatus, open, handleClose } = props
  const { userInfo } = useMenus()
  const [currentSubMenu, setCurrentSubMenu] = useState([])
  const [currentSubMenuPosition, setCurrentSubMenuPosition] = useState<{
    top: number
  }>(null)

  const handleItemClick: MenuItemClick = useCallback(({ sub, isSub }) => {
    return (event) => {
      if (isSub) return
      const subList = sub?.length ? sub.map((item: MenuItem) => ({ ...item, isSub: true })) : []
      setCurrentSubMenu(subList)
      setCurrentSubMenuPosition(event.currentTarget.getBoundingClientRect() || null)
    }
  }, [])
  const popperMenuRef = useRef(null)
  const screenSize = useRef('')
  const calcSubMenuPosition = useMemo(() => {
    const popperTop = popperMenuRef?.current?.getBoundingClientRect()?.top ?? 0
    const popperRight = popperMenuRef?.current?.getBoundingClientRect().right ?? 0
    const elementWidth = popperMenuRef?.current?.getBoundingClientRect().width ?? 0
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0
    const subMenuTop = currentSubMenuPosition?.top ?? 0
    return {
      top: subMenuTop - popperTop,
      // Check the position is not overflow the window
      right: innerWidth - popperRight < elementWidth ? elementWidth * 2 : 0,
    }
  }, [currentSubMenuPosition, popperMenuRef])

  useEffect(() => {
    if (window.matchMedia(`(min-width: ${theme`screens.md`})`).matches) {
      screenSize.current = ScreenSize.desktop
    } else {
      screenSize.current = ScreenSize.mobile
    }
  }, [])

  const popperContent = [
    userStatus === UserStatus.guest && <GuestMenu key="guestMenu" />,
    userStatus === UserStatus.member && <MemberMenu key="memberMenu" />,
    userStatus === UserStatus.creator && <CreatorMenu key="creatorMenu" />,
  ]

  const drawerHeader = <UserItem {...userInfo} userStatus={userStatus} />

  return (
    <HeaderMenuContext.Provider
      value={{
        handleItemClick,
        screenSize: screenSize?.current ?? '',
      }}
    >
      <Popper
        open={open && screenSize.current === ScreenSize.desktop}
        size={PopperSize.small}
        ref={popperMenuRef}
      >
        {currentSubMenu.length > 0 && (
          <DropdownSubMenu position={calcSubMenuPosition}>
            <Dropdown
              data={[currentSubMenu]}
              screenSize={screenSize.current}
              handleItemClick={handleItemClick}
            />
          </DropdownSubMenu>
        )}
        <PopperContent>{popperContent}</PopperContent>
      </Popper>
      <Drawer
        header={drawerHeader}
        open={open && screenSize.current === ScreenSize.mobile}
        handleClose={handleClose}
        headerHeight={60}
        root="#headerMenuRoot"
      >
        {popperContent}
      </Drawer>
    </HeaderMenuContext.Provider>
  )
}

export default HeaderMenu
