import { getEventTracker } from '@libs/eventTracking'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'

export function trackWebHeaderEnterECPromotionBanner(input) {
  const { pageName, pageUrl } = input.currentPage
  getEventTracker().track({
    name: WebEventNames.WEB_HEADER_ENTER_EC_PROMOTION_BANNER,
    properties: {
      currentPage: getCurrentPagePropertyFor(pageName, pageUrl),
      toPage: getNextPagePropertyFor(input.toPage),
    },
  })
}
