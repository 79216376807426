import { ElementType } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { imageUrl } from '@libs/appUrl'
import useSignOut from '@hooks/useSignOut'
import {
  FireIcon,
  AlertCircleIcon,
  RandomTalkIcon,
  UsersIcon,
  UserIcon,
  MapPinSolidIcon,
  SettingIcon,
  LogoutIcon,
  PhoneIcon,
  EditIcon,
  Explore,
  StarIcon,
} from '@components/Icons'
import UserItem from './UserItem'
import { paths, links } from '@routes/header-paths'
import NotificationItem from './NotificationItem'
import getConfig from 'next/config'

const {
  publicRuntimeConfig: { popSelectEnabled },
} = getConfig()

// Tracking
import { dispatchEvent, dispatchCustomEvent, HeaderMenusEvents } from '@libs/customEvents'

export type MenuItem = {
  name?: string
  className?: string
  icon?: ElementType
  sub?: MenuItem[]
  href?: string
  active?: boolean
  isSub?: boolean
  customComponent?: {
    target: any
    props: {
      [key: string]: any
    }
  }
  event?: () => void
  newTab?: boolean
}

const useMenus = () => {
  const { t } = useTranslation('header')
  const { avatar, userName, mid } = useSelector((state: any) => {
    const userInfo = state.currentUser.get('userInfo').toJS()
    return {
      avatar: imageUrl(userInfo.img),
      userName: userInfo?.name ?? t('defaultUserName'),
      mid: userInfo?.mid ?? '',
    }
  })
  const signOut = useSignOut()
  const UserItems = [
    {
      customComponent: {
        target: UserItem,
        props: { avatar, userName },
      },
    },
  ]
  const MemberDesktopItems = [
    {
      name: t('menu.personal'),
      icon: UserIcon,
      href: paths.user,
      event: () =>
        dispatchCustomEvent(HeaderMenusEvents.ENTER_USER_PROFILE, {
          toPageMidOrMe: 'me',
        }),
    },
    {
      name: t('menu.setting'),
      icon: SettingIcon,
      href: paths.setting,
      event: () => dispatchEvent(HeaderMenusEvents.ENTER_SETTING),
    },
  ]

  let MemberMobileItems: MenuItem[] = [
    {
      name: t('menu.personal'),
      icon: UserIcon,
      href: paths.user,
    },
    {
      name: t('menu.setting'),
      icon: SettingIcon,
      href: paths.setting,
    },
  ]

  let CreatorItems: MenuItem[] = [
    {
      name: t('menu.backstage'),
      icon: Explore,
      href: paths.creatorDashboard,
      event: () => dispatchEvent(HeaderMenusEvents.WEB_HEADER_MENU_ENTER_CREATOR_DASHBOARD),
    },
    {
      name: t('menu.newArticle'),
      icon: EditIcon,
      href: paths.newArticle,
      event: () => dispatchEvent(HeaderMenusEvents.ENTER_EDITOR),
    },
    ...MemberDesktopItems,
  ]

  if (popSelectEnabled) {
    MemberMobileItems = [
      { customComponent: { target: NotificationItem, props: {} } },
      ...MemberMobileItems,
    ]

    CreatorItems = [{ customComponent: { target: NotificationItem, props: {} } }, ...CreatorItems]
  }
  const AreaItems = [
    {
      name: t('menu.area'),
      icon: MapPinSolidIcon,
      sub: [
        {
          name: t('menu.taiwan'),
          active: true,
        },
        {
          name: t('menu.malaysiaMandarin'),
          href: links.malaysiaMandarin,
        },
      ],
    },
  ]
  const InfoItems = [
    {
      name: t('becomeCreator'),
      icon: StarIcon,
      href: paths.poper,
    },
    {
      name: t('menu.about'),
      icon: FireIcon,
      href: paths.about,
    },
    {
      name: t('menu.privacy'),
      icon: AlertCircleIcon,
      href: paths.privacy,
    },
    {
      name: t('menu.contactUs'),
      icon: RandomTalkIcon,
      sub: [
        {
          name: t('menu.report'),
          href: paths.report,
        },
        {
          name: t('menu.cooperation'),
          href: paths.contact,
        },
        {
          name: t('menu.copyrightReport'),
          href: paths.copyright,
        },
      ],
    },
    {
      name: t('menu.recruitment'),
      icon: UsersIcon,
      href: paths.hiring,
      newTab: true,
    },
  ]
  const OpenPopDailyAppItems = [
    {
      name: t('menu.openApp'),
      icon: PhoneIcon,
      className: 'openApp',
      href: links.appDeepLink,
      active: true,
    },
  ]
  const SignOutItems = [
    {
      name: t('menu.signOut'),
      icon: LogoutIcon,
      event: () => {
        signOut()
        dispatchEvent(HeaderMenusEvents.LOGOUT)
      },
    },
  ]

  return {
    UserItems,
    MemberDesktopItems,
    MemberMobileItems,
    CreatorItems,
    AreaItems,
    InfoItems,
    SignOutItems,
    OpenPopDailyAppItems,
    userInfo: {
      avatar,
      userName,
      mid,
    },
  }
}

export default useMenus
