import { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { DropdownItem, DropdownItemTitle } from './Dropdown.styled'
import NotificationIcon from '@components/Icons/NotificationIcon'
import NotificationOnIcon from '@components/Icons/NotificationOnIcon'
import { useSelector } from 'react-redux'
import Badge, { BadgeVariant } from '@components/DesignSystem/Badge'
import NotifyPopper from '../Buttons/NotificationIconButton/NotifyPopper'
import { useTranslation } from '@i18n'

const StyledBadge = styled(Badge)`
  justify-self: flex-end;
`

const StyledNotificationDropdownItem = styled(DropdownItem)`
  ${tw`md:hidden`};
`

export type UserInfo = {
  userName?: string
  avatar?: string
  mid?: string
}

export enum UserStatus {
  guest = 'guest',
  member = 'member',
  creator = 'creator',
}

const NotificationItem = () => {
  const [visible, setVisible] = useState(false)
  const notifyCount = useSelector<any, number>((state) =>
    state.notify.getIn(['notifyState', 'newMsg']),
  )
  const { t } = useTranslation('header')

  const onClick = () => setVisible((value) => !value)
  const onClose = () => setVisible(false)

  const hasNotify = notifyCount !== 0
  const Icon = hasNotify ? NotificationOnIcon : NotificationIcon

  return (
    <>
      <StyledNotificationDropdownItem onClick={onClick}>
        <DropdownItemTitle active={hasNotify}>
          <Icon className="icon" />
          <p>{t('menu.notify')}</p>
          {hasNotify && <StyledBadge variant={BadgeVariant.primary}>{notifyCount}</StyledBadge>}
        </DropdownItemTitle>
      </StyledNotificationDropdownItem>
      <NotifyPopper open={visible} handleClose={onClose} />
    </>
  )
}

export default NotificationItem
