import { useEffect, ReactNode, useRef } from 'react'
import tw, { styled, theme } from 'twin.macro'
import PopdailyLogo from '@components/Icons/PopdailyLogo'
import { useTranslation } from '@i18n'

import SearchIconButton from './Buttons/SearchIconButton'
import AllIconButton from './Buttons/AllIconButton/AllIconButton'
import NotificationIconButton from './Buttons/NotificationIconButton/NotificationIconButton'
import UserButton from './Buttons/UserButton'
import Placeholder from './Buttons/Placeholder'
import PopSelectLink from './Buttons/PopSelectLink'

import Button, { ButtonVariant, mediumStyle, largeStyle } from '@components/DesignSystem/Button'

import { useDispatch, useSelector } from 'react-redux'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation/Navigation'

import { checkNotify } from '@app-core/notify/actions'
import { paths } from '@routes/header-paths'

// Tracking
import { withHeaderEventTracking } from '@features/post/hocs/withHeaderEventTracking'
import { dispatchEvent, HeaderEvents } from '@libs/customEvents'

import { useAmp } from 'next/amp'
import throttle from 'lodash/throttle'
import getConfig from 'next/config'

const {
  publicRuntimeConfig: { popSelectEnabled },
} = getConfig()

type BackgroundProps = {
  isAmp?: boolean
}

const Background = styled.div<BackgroundProps>`
  ${tw`bg-white left-0 z-mask`}
  --header-height: 0px;
  --is-header-visible: 0;
  transition: top 0.3s ease;
  will-change: top;
  top: calc(var(--header-height) * var(--is-header-visible) + var(--download-bar-height));
  ${tw`md:top-0`};
  position: ${(props) => (props.isAmp ? 'static' : 'sticky')};
`

const Container = styled.div`
  font-size: 16px;
  max-width: 1168px;
  margin: 0 auto;

  ${tw`flex items-center`}
  ${tw`px-4`}

  height: 48px;
  @media (min-width: ${theme`screens.md`}) {
    height: 80px;
  }

  ${Button} {
    ${mediumStyle};
    @media (min-width: ${theme`screens.md`}) {
      ${largeStyle}
    }
  }
`

const LogoContainer = styled.a`
  margin-right: 22px;
`

const BecomeCreatorButton = styled(Button)`
  ${tw`hidden md:flex`}
`

const LoginButton = styled(Button)`
  ${popSelectEnabled && tw`hidden md:inline-flex`}
`

const ButtonContainer = styled.div`
  ${tw`flex flex-grow justify-end items-center`}
  & > * + * {
    margin-left: 8px;
  }

  @media (min-width: ${theme`screens.md`}) {
    & > * + * {
      margin-left: 12px;
    }
  }
`

const Location = styled.div`
  ${tw`hidden text-black font-medium mr-4 md:block`}
`

type HeaderProps = {
  isApp?: boolean
  isArticlePage?: boolean
  location?: string
  children?: ReactNode
  hideSearch?: boolean
  hasNavigation?: boolean
  eventCategories?: [
    {
      id: string
      name: string
      description?: string
    },
  ]
}

function Header(props: HeaderProps) {
  const isAmp = useAmp()
  const currentUser = useSelector<any, any>((state) => state.currentUser)

  const { isApp, isArticlePage, hasNavigation, location, children, hideSearch, eventCategories } =
    props
  const { t } = useTranslation('header')

  const renderButtons = () => {
    if (isApp) return null

    const userInfo = currentUser.get('userInfo').toJS()

    if (!isAmp && userInfo.type === 'unknown') return <Placeholder />

    const searchButton = !hideSearch && (
      <SearchIconButton
        onClick={() => dispatchEvent(HeaderEvents.ENTER_SEARCH)}
        path={paths.search}
      />
    )

    const popSelectLink = popSelectEnabled && <PopSelectLink isArticlePage={isArticlePage} />

    if (isAmp || userInfo.type === 'guest') {
      return (
        <>
          {searchButton}
          {!isAmp && <AllIconButton />}
          <LoginButton
            onClick={() => dispatchEvent(HeaderEvents.ENTER_REGISTER_LOGIN)}
            as="a"
            href={paths.login}
          >
            {t('login')}
          </LoginButton>
          {popSelectLink}
        </>
      )
    }

    const isCreator = userInfo.role.creator === 1
    const pictureUrl = userInfo.img

    return (
      <>
        {searchButton}
        <NotificationIconButton />
        <UserButton isCreator={isCreator} pictureUrl={pictureUrl} />
        {!isCreator && !popSelectEnabled && (
          <BecomeCreatorButton
            onClick={() => dispatchEvent(HeaderEvents.ENTER_CREATOR_RECRUITMENT)}
            variant={ButtonVariant.primary}
            as="a"
            href={paths.poper}
          >
            {t('becomeCreator')}
          </BecomeCreatorButton>
        )}
        {popSelectLink}
      </>
    )
  }
  const dispatch = useDispatch()
  useEffect(() => dispatch(checkNotify({})), [dispatch])

  const { stickyElementRef } = useStickyScrollBehavior()

  if (isApp) return null

  return (
    <>
      <Background ref={stickyElementRef} isAmp={isAmp}>
        <Container>
          <LogoContainer
            onClick={() => dispatchEvent(HeaderEvents.ENTER_TW_POPDAILY)}
            href={paths.index}
          >
            <PopdailyLogo />
          </LogoContainer>
          {location && <Location>{location}</Location>}
          {hasNavigation && <DesktopNavigation eventCategories={eventCategories} />}
          {children}
          <ButtonContainer>{renderButtons()}</ButtonContainer>
        </Container>
        {hasNavigation && <MobileNavigation eventCategories={eventCategories} />}
      </Background>
      {/* 為了避免 Drawer 會被 DownloadBar 遮住 */}
      <div id="headerMenuRoot" />
      <div id="notifyPopperRoot" />
    </>
  )
}

const useStickyScrollBehavior = () => {
  const stickyElementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!stickyElementRef.current) return

    stickyElementRef.current.style.setProperty(
      '--header-height',
      stickyElementRef.current.clientHeight + 'px',
    )
  }, [])

  useEffect(() => {
    if (!stickyElementRef.current) return

    let lastScrollY = 0
    let cumulativeY = 0

    const onScroll = throttle(() => {
      const { scrollY } = window
      const delta = scrollY - lastScrollY
      lastScrollY = scrollY

      // 如果累積方向與變化方向相反
      if (cumulativeY * delta < 0) cumulativeY = 0
      cumulativeY += delta
      if (Math.abs(cumulativeY) < 50) return

      stickyElementRef.current.style.setProperty('--is-header-visible', delta < 0 ? '0' : '-1')
    }, 200)

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return { stickyElementRef }
}

export default withHeaderEventTracking(Header)
