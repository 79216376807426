import tw, { styled, css, theme } from 'twin.macro'
import nav from '@routes/nav'

type ChevronIconBoxProps = {
  visible: boolean
}

export const eventCategoryStyle = css`
  color: #fc6868;
  font-weight: bolder;

  &[href='/explore/2022chinesevalentines'] {
    color: #f65d68;
    font-weight: bolder;
  }
  &[href='/explore/2022valentines'] {
    color: #f65d68;
    font-weight: bold;
  }
  &[href='/explore/2021popanniversary'] {
    color: #ff5809;
    font-weight: bold;
  }

  &[href='/2021popxmas'],
  &[href='/explore/2021popxmas'] {
    color: #e45656;
    font-weight: bold;
  }

  ${nav.categories.map(
    (category) => `
    &[href='/${category}'],
    &[href='/explore/${category}'] {
      color: #888;
      font-weight: normal;
    }
  `,
  )}
`

export const MobileOnlyContainer = styled.div`
  ${tw`relative md:hidden`}
`

export const Container = styled.div`
  ${tw`overflow-x-auto`};
  width: calc(100% - 52px);
  text-align: center;
  line-height: 1;
  white-space: nowrap;
`

export const ChevronIconBox = styled.div<ChevronIconBoxProps>`
  width: 52px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  ${(props) => (props.visible ? `height: 44px` : 'height: 35px')}
`

export const CategoryLink = styled.a`
  color: #fc6868;
  background-color: #fff;
  padding: 8px 6px;
  line-height: 1.4;
  font-size: 14px;

  &:first-child {
    padding-left: 12px;
  }

  &:last-child {
    padding-right: 12px;
  }

  @media (min-width: ${theme`screens.md`}) {
    padding: 12px 6px;
    font-size: 16px;
  }
  display: inline-block;

  ${eventCategoryStyle};
`
