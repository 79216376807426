import React from 'react'
import { CategoryLink, Container, TitleBar, ChipBtn } from './CategoryNavigationPanel.styled'

const CategoryNavigationPanel = (props) => {
  const { categories, events, t, onClickEventTrack } = props

  return (
    <Container>
      <TitleBar>分類</TitleBar>
      {categories.map((category, index) => {
        const pathname = `/explore/${category}`
        return (
          <CategoryLink
            key={index}
            href={pathname}
            onClick={(e) => onClickEventTrack(e, category, pathname)}
          >
            <ChipBtn>{events[category] || t(`${category}.name`)}</ChipBtn>
          </CategoryLink>
        )
      })}
    </Container>
  )
}

export default CategoryNavigationPanel
