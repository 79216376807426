import { useState } from 'react'
import tw, { styled, theme, css } from 'twin.macro'
import { imageUrl } from '@libs/appUrl'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import HeaderMenu, { UserStatus } from '../HeaderMenu'
import { useSelector } from 'react-redux'
import { NotifyCount } from './NotificationIconButton/NotificationIconButton'
import getConfig from 'next/config'

const {
  publicRuntimeConfig: { popSelectEnabled },
} = getConfig()

export const Button = styled.button`
  width: 40px;
  height: 40px;
  ${tw`flex justify-center items-center hover:opacity-60`}
`

const Img = styled.img`
  width: 36px;
  height: 36px;

  ${tw`border-2 border-gray-30`};
  ${tw`rounded-full overflow-hidden`};

  @media (min-width: ${theme`screens.md`}) {
    width: 40px;
    height: 40px;
  }
`

const hasNotifyStyle = css`
  ${Img} {
    ${tw`border-primary-indigo`}
  }
  ${NotifyCount} {
    top: -0.5px;
    right: 0;
    left: auto;
    transform-origin: top right;
    display: block;
  }
  @media (min-width: ${theme`screens.md`}) {
    ${Img} {
      ${tw`border-gray-30`}
    }
    ${NotifyCount} {
      display: none;
    }
  }
`

type ContainerProps = {
  hasNotify?: boolean
}

const Container = styled.div<ContainerProps>`
  position: relative;
  ${NotifyCount} {
    display: none;
  }
  ${(props) => props.hasNotify && popSelectEnabled && hasNotifyStyle}
`

type Props = {
  pictureUrl: string
  isCreator: boolean
}

function UserButton(props: Props) {
  const { pictureUrl, isCreator } = props
  const [visible, setVisible] = useState(false)
  const notifyCount = useSelector<any, number>((state) =>
    state.notify.getIn(['notifyState', 'newMsg']),
  )

  const onClick = () => setVisible((value) => !value)
  const onClose = () => setVisible(false)
  const hasNotify = notifyCount !== 0

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Container hasNotify={hasNotify}>
        <Button onClick={onClick}>
          <Img src={imageUrl(pictureUrl)} alt="" />
          {hasNotify && <NotifyCount>{notifyCount > 99 ? '99+' : notifyCount}</NotifyCount>}
        </Button>
        <HeaderMenu
          open={visible}
          userStatus={isCreator ? UserStatus.creator : UserStatus.member}
          handleClose={onClose}
        />
      </Container>
    </ClickAwayListener>
  )
}

export default UserButton
