import React, { useState, useEffect } from 'react'

type UseTransitionProps = {
  open: boolean
  transitionDuration?: number
  children: (value: { isTransition: boolean; transitionDuration: number }) => React.ReactNode
}
const Transition = (props: UseTransitionProps) => {
  const { open, children, transitionDuration = 300 } = props
  const [visible, setVisible] = useState(open)
  const isDisplay = open ? open : visible
  const isTransition = open ? visible : open
  useEffect(() => {
    let closeTimer, openTimer
    if (open) {
      openTimer = setTimeout(() => {
        setVisible(true)
      }, 0)
    } else {
      closeTimer = setTimeout(() => {
        setVisible(false)
      }, transitionDuration)
    }
    return () => {
      if (closeTimer) clearTimeout(closeTimer)
      if (openTimer) clearTimeout(openTimer)
    }
  }, [open, transitionDuration])
  return <>{isDisplay && children({ isTransition, transitionDuration })}</>
}

export default Transition
