import SearchIcon from '@components/Icons/SearchIcon'
import IconButton from './IconButton'

function SearchIconButton({ path, onClick }) {
  return (
    <IconButton onClick={onClick} as="a" href={path}>
      <SearchIcon />
    </IconButton>
  )
}

export default SearchIconButton
