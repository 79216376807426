import { useCallback, useEffect, useRef, useState } from 'react'
import nav from '@routes/nav'
import { useSelector } from 'react-redux'
import { useTranslation } from '@i18n'
import withBeacon from '@hocs/withBeacon'
import CategoryNavigationPanel from './CategoryNavigationPanel'
import { MobileOnlyContainer, Container, CategoryLink, ChevronIconBox } from './Navigation.styled'
import { ChevronUpIcon, ChevronDownIcon } from '@components/Icons'
import { publicUrl } from '@libs/appUrl'
import { trackHeaderCategoryBarEnterWebCategoryEvent } from '@libs/eventTracking/header/web-header.category-bar.enter-web-category'

type Props = {
  gtmEventTrack: any
  eventCategories: any
}

function Navigation(props: Props) {
  const { gtmEventTrack, eventCategories = [] } = props
  const type = useSelector<any, string>((state) => {
    if (state.post.hasIn(['post', 'data', 'type']))
      return state.post.getIn(['post', 'data', 'type']) === 'web' ? 'website' : 'explore'

    return state.posts.getIn(['posts', 'type'])
  })
  const activeCategory = useSelector<any, string>((state) => {
    if (state.post.hasIn(['post', 'data', 'clas', '0']))
      return state.post.getIn(['post', 'data', 'clas', '0'])

    return state.posts.getIn(['posts', 'clas'])
  })
  const { t } = useTranslation('nav')
  const { containerRef, activeCategoryLinkRef } = useScrollToActiveCategoryLink(activeCategory)
  const [visible, setVisible] = useState(false)

  const onClick = useCallback(
    (e, category, pathname) => {
      e.preventDefault()
      const url = publicUrl(pathname)
      trackHeaderCategoryBarEnterWebCategoryEvent({
        toPageUrl: url,
        category,
      })

      if (gtmEventTrack) {
        gtmEventTrack('top nav', {
          eventCategory: 'top nav',
          eventAction: 'click',
          eventLabel: `${type}-${activeCategory}`,
        })
      }

      window.location.href = url
    },
    [gtmEventTrack, type, activeCategory],
  )
  const events = {}
  const allCategories = [...nav.categories]
  for (const eventCategory of eventCategories) {
    allCategories.splice(allCategories.length - nav.categories.length + 1, 0, eventCategory.id)
    events[eventCategory.id] = eventCategory.name
  }

  return (
    <MobileOnlyContainer>
      <Container ref={containerRef}>
        {allCategories.map((category, index) => {
          const active = category === activeCategory
          const pathname = `/explore/${category}`
          return (
            <CategoryLink
              key={index}
              href={pathname}
              ref={active ? activeCategoryLinkRef : undefined}
              onClick={(e) => onClick(e, category, pathname)}
            >
              {events[category] || t(`${category}.name`)}
            </CategoryLink>
          )
        })}
      </Container>

      {/* mweb header*/}
      <ChevronIconBox visible={visible} onClick={() => setVisible(!visible)}>
        {visible ? (
          <ChevronUpIcon width="20px" height="20px" />
        ) : (
          <ChevronDownIcon width="20px" height="20px" />
        )}
      </ChevronIconBox>

      {visible && (
        <CategoryNavigationPanel
          events={events}
          categories={allCategories}
          t={t}
          onClickEventTrack={onClick}
        />
      )}
    </MobileOnlyContainer>
  )
}

export default withBeacon(Navigation)

const useScrollToActiveCategoryLink = (activeCategory: string) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const activeCategoryLinkRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (!containerRef.current || !activeCategoryLinkRef.current) return

    containerRef.current.scrollTo({
      left: activeCategoryLinkRef.current.offsetLeft,
      behavior: 'smooth',
    })
  }, [activeCategory])

  return { containerRef, activeCategoryLinkRef }
}
