import React from 'react'
import styles from './index.scss'

const Loading = () => {
  return (
    <div className="popapp-loading popapp-loading-type1">
      <style jsx>{styles}</style>
      <i key="loading-1" />
      <i key="loading-2" />
    </div>
  )
}

export default Loading
