import { ArticleId, ReplyId } from '@app-core/eventTracking/properties'

export const REPLY_OPEN = 'reply.open'
export const REPLY_CLOSE = 'reply.close'
export const REPLY_FINISH = 'reply.finish'

export interface SharedEventDetail {
  component:
    | 'bottom-anchor'
    | 'comment' // 表示針對整個留言區塊
    | 'reply' // 表示針對單一留言的回覆
}

export function dispatchReplyOpenEvent<T = SharedEventDetail>(detail?: T) {
  window.dispatchEvent(
    new CustomEvent(REPLY_OPEN, {
      detail,
    }),
  )
}

export function dispatchReplyCloseEvent<T = SharedEventDetail>(detail?: T) {
  window.dispatchEvent(
    new CustomEvent(REPLY_CLOSE, {
      detail,
    }),
  )
}

export interface ReplyFinishEventDetail {
  status: 'succeeded' | 'failed'
  articleId: ArticleId
  replyId: ReplyId
}

export function dispatchReplyFinishEvent<T = ReplyFinishEventDetail>(detail?: T) {
  window.dispatchEvent(new CustomEvent(REPLY_FINISH, { detail }))
}
