import tw, { styled } from 'twin.macro'

const Square = styled.div`
  width: 40px;
  height: 40px;
  ${tw`bg-gray-30`}
  border-radius: 12px;

  &:not(:last-child) {
    margin-right: 12px;
  }
`

const Rectangle = styled(Square)`
  width: 80px;
  border-radius: 24px;
`

function Placeholder() {
  return (
    <>
      <Square />
      <Square />
      <Rectangle />
    </>
  )
}

export default Placeholder
