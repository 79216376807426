import { getEventTracker } from '@libs/eventTracking'
import {
  getFromPagePropertyFor,
  getCurrentPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'

/**
 * [Link to spec](https://www.dropbox.com/scl/fi/d456rqqdryhjnqr5quek2/%E3%80%90%E6%95%B8%E6%93%9A%E3%80%91-%E9%80%9A%E7%9F%A5%E4%B8%AD%E5%BF%83(%E9%A0%81)-APP-%26-WEB.paper?dl=0&rlkey=jll1g36jtxse06goqjrjmc2oe#:uid=593709003875269511819075&h2=[FR-21642]-[Web]-[Mixpanel]-%E9%80%9A%E7%9F%A5)
 */
export function trackNotificationViewEvent(input) {
  const { pageName } = input.currentPage
  getEventTracker().track({
    name: WebEventNames.NOTIFICATION_VIEW,
    properties: {
      fromPage: getFromPagePropertyFor(input.fromPage),
      currentPage: getCurrentPagePropertyFor(pageName),
    },
  })
}
