import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { updateFollowState, readNotify } from '@app-core/notify/actions'
import { withContext, compose, connect } from '@hocs'
import useScreenSize, { ScreenSize } from '@hooks/useScreenSize'
import Popper, { PopperHeader, PopperContent, PopperSize } from '@components/Popper'
import NotFoundSVG from '@components/NotFoundSVG'
import Notify from '@features/notify/components/Notify'
import useNotificationData from './useNotificationData'

import dynamic from 'next/dynamic'
const Drawer = dynamic(() => import('@components/Drawer'), { ssr: false })

type NotifyPopperProps = {
  open: boolean
  handleClose: () => void
}

const NotFound = () => {
  const { t } = useTranslation('notify')
  return (
    <NotFoundWrapper>
      <NotFoundSVG />
      <p>{t('not_found')}</p>
    </NotFoundWrapper>
  )
}

const NotifyPopper: React.FC<NotifyPopperProps> = (props) => {
  const { open, handleClose } = props
  const {
    userNotifyList,
    systemNotifyList,
    userNotifyListSize,
    systemNotifyListSize,
    loadMore,
  } = useNotificationData({ open })
  const screenSize = useScreenSize()
  const { t } = useTranslation('notify')
  const handleClickClose = () => handleClose()
  const isEmptyNotify = systemNotifyListSize === 0 && userNotifyListSize === 0
  return (
    <>
      <Popper
        open={open && screenSize === ScreenSize.desktop}
        fixedHeader={true}
        size={PopperSize.medium}
      >
        <PopperHeader>
          <HeaderTitle>{t('title')}</HeaderTitle>
        </PopperHeader>
        <NotifyPopperContent>
          {isEmptyNotify ? (
            <NotFound />
          ) : (
            <Notify
              onLoadMore={loadMore}
              userNotifyList={userNotifyList}
              systemNotifyList={systemNotifyList}
            />
          )}
        </NotifyPopperContent>
      </Popper>
      <Drawer
        open={open && screenSize === ScreenSize.mobile}
        handleClose={handleClickClose}
        header={<HeaderTitle>{t('title')}</HeaderTitle>}
        root="#notifyPopperRoot"
      >
        <NotifyDrawerContent>
          {isEmptyNotify ? (
            <NotFound />
          ) : (
            <Notify
              onLoadMore={loadMore}
              userNotifyList={userNotifyList}
              systemNotifyList={systemNotifyList}
            />
          )}
        </NotifyDrawerContent>
      </Drawer>
    </>
  )
}

/***
 * compose 這邊是為了 Notify -> NotifyItem 裡面 getContext 用到
 * 之後 NotifyItem 重構後拿掉 getContext 這邊也就可以拿掉了
 */

const mapStateToProps = () => ({})

export default compose(
  connect(mapStateToProps, {
    updateFollowState,
    readNotify,
  }),
  withContext(
    {
      updateFollowState: PropTypes.func.isRequired,
      readNotify: PropTypes.func.isRequired,
      openTargetBehavior: PropTypes.string,
    },
    (dispatchProps) => ({
      updateFollowState: dispatchProps.updateFollowState,
      readNotify: dispatchProps.readNotify,
      openTargetBehavior: '_self',
    }),
  ),
)(NotifyPopper)

const NotifyDrawerContent = styled.div`
  padding-right: 8px;
  padding-left: 8px;
`
const NotifyPopperContent = styled(PopperContent)`
  padding-right: 8px;
  padding-left: 8px;
  > .notify {
    > .title {
      padding: 8px 8px 20px !important;
    }
  }
`
const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  ${tw`h-12 flex px-4 items-center h-full`};
  @media (max-width: 768px) {
    font-size: 20px;
    padding: 0;
    ${tw`h-auto flex-1`};
  }
`
const NotFoundWrapper = styled.div`
  height: 380px;
  ${tw`flex items-center justify-center flex-col`};
  > svg {
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {
    ${tw`h-full`};
  }
`
