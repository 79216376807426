import { useCallback } from 'react'

const useSignOut = () => {
  const signOut = useCallback(() => {
    const domain = window.location.hostname.includes('popdaily') ? ';domain=.popdaily.com.tw' : ''
    const keys = ['user.info', 'user.token', 'user.mid']
    keys.forEach((key) => {
      window.localStorage.removeItem(key)
      document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/${domain}`
    })

    window.location.reload()
  }, [])

  return signOut
}

export default useSignOut
