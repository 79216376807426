import { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import ChevronDownIcon from '@components/Icons/ChevronDownIcon'
import nav from '@routes/nav'
import { useTranslation } from '@i18n'
import { eventCategoryStyle } from './MobileNavigation/Navigation.styled'
import { publicUrl } from '@libs/appUrl'
import { trackHeaderCategoryBarEnterWebCategoryEvent } from '@libs/eventTracking/header/web-header.category-bar.enter-web-category'

const DesktopOnlyContainer = styled.div`
  ${tw`hidden md:flex items-center self-stretch relative`}

  & > * + * {
    margin-left: 24px;
  }
`

type Props = {
  visible: boolean
}

const Button = styled.button<Props>`
  font-weight: 500;
  color: #999999;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 12px;

  & > * + * {
    margin-left: 4px;
  }

  &:hover {
    background: #f7f7f7;
  }
  ${(props) => props.visible && 'background: #f7f7f7'}
`

/**
 * MAX:
 *   為了讓 SEO 可以爬到分類頁
 *   所以使用 CSS 隱藏 Popup
 */
const Popup = styled.div<Props>`
  background: white;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;

  ${(props) => !props.visible && 'display: none'}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-rows: repeat(6, auto);
  grid-auto-flow: column;
  gap: 7px 12px;
`

const CategoryLink = styled.a`
  display: block;
  min-width: 104px;
  padding: 8px 12px;
  border-radius: 4px;
  height: 36px;
  text-align: left;
  font-weight: 500;
  line-height: 1.4;
  color: #333333;

  &:hover {
    background: #f2f2ff;
  }
`

const EventLink = styled.a`
  ${eventCategoryStyle}
`

const CategoryButton = ({ eventCategories = [] }) => {
  const { t } = useTranslation('nav')
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!visible) return
    const closePopup = () => setVisible(false)

    window.addEventListener('click', closePopup)

    return () => window.removeEventListener('click', closePopup)
  }, [visible])

  const handleCategoryOnClick = (e, category) => {
    e.preventDefault()
    const url = publicUrl(e.target.href)
    trackHeaderCategoryBarEnterWebCategoryEvent({
      toPageUrl: url,
      category,
    })
    window.location.href = url
  }

  return (
    <DesktopOnlyContainer>
      <Button visible={visible} onClick={() => setVisible(true)}>
        <span>分類</span> <ChevronDownIcon width="18" height="18" />
        <Popup visible={visible}>
          <GridLayout>
            {nav.categories.map((category) => {
              const pathname = `/explore/${category}`
              return (
                <CategoryLink
                  key={category}
                  onClick={(e) => handleCategoryOnClick(e, category)}
                  href={pathname}
                >
                  {t(`${category}.name`)}
                </CategoryLink>
              )
            })}
          </GridLayout>
        </Popup>
      </Button>
      {eventCategories.map((event) => {
        return (
          <EventLink
            key={event}
            href={`/explore/${event.id}`}
            onClick={(e) => handleCategoryOnClick(e, event)}
          >
            {'#' + event.name}
          </EventLink>
        )
      })}
    </DesktopOnlyContainer>
  )
}

export default CategoryButton
