import { FC, MouseEventHandler, ElementType } from 'react'
import tw, { styled, css, theme } from 'twin.macro'

const baseStyle = css`
  font-weight: 500;
  line-height: 1;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  transition: 0.1s;

  ${tw`bg-gray-30`}
  ${tw`text-black`}

  &[disabled] {
    opacity: 0.3;
  }

  &:hover {
    ${tw`text-opacity-60`}
  }

  &:active {
    ${tw`text-opacity-60`}
    background-color: #e6e6e6;
  }
`

const primaryStyle = css`
  ${tw`bg-primary-indigo text-white`}

  &:active {
    ${tw`bg-primary-darkGrey`}
  }
`

export const largeStyle = css`
  font-size: 16px;
  padding: 0 20px;
  height: 40px;

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
`
const blockStyle = css`
  ${largeStyle}
  width: 100%;
  height: 56px;
`
export const mediumStyle = css`
  font-size: 14px;
  padding: 0 12px;
  height: 32px;

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`

const smallStyle = css`
  ${mediumStyle}
  font-size: 12px;
  height: 24px;
`

const iconStyle = css`
  font-size: 0;
  width: 32px;
  height: 32px;

  & > svg {
    width: 16px;
    height: 16px;
  }
`

const onImageStyle = tw`bg-gray-800 bg-opacity-50 border border-solid border-gray-800 border-opacity-60 text-white`

const secondaryStyle = tw`border border-solid border-gray-100 text-gray-200 bg-white`

const outlinePrimaryStyle = css`
  background-color: transparent;
  border: 1px solid ${theme`colors.primary.normal`};
  color: ${theme`colors.primary.normal`};
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
`

export enum ButtonSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
  block = 'block',
  icon = 'icon',
}

export enum ButtonVariant {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  onImage = 'onImage',
  outlinePrimary = 'outlinePrimary',
}

type Props = {
  size?: ButtonSize
  variant?: ButtonVariant
  onClick?: MouseEventHandler
  as?: ElementType
  disabled?: boolean
  className?: string
}

const Button = styled.button<Props>(({ size = 'large', variant = 'default' }) => [
  baseStyle,
  size === ButtonSize.large && largeStyle,
  size === ButtonSize.medium && mediumStyle,
  size === ButtonSize.icon && variant === ButtonVariant.secondary && secondaryStyle,
  (size === ButtonSize.medium || size === ButtonSize.icon) &&
    variant === ButtonVariant.onImage &&
    onImageStyle,
  size === ButtonSize.small && smallStyle,
  size === ButtonSize.block && blockStyle,
  size === ButtonSize.icon && iconStyle,
  variant === ButtonVariant.primary && primaryStyle,
  variant === ButtonVariant.outlinePrimary && outlinePrimaryStyle,
])

export default Button
export const TypeOverwriteForStorybook: FC<Props> = Button
