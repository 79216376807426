export enum WebEventNames {
  // header
  WEB_HEADER_ENTER_CREATOR_RECRUITMENT = 'web-header.enter-creator-recruitment',
  WEB_HEADER_ENTER_TW_POPDAILY = 'web-header.enter-tw-popdaily',
  WEB_HEADER_ENTER_EDITOR = 'web-header.menu.enter-editor',
  WEB_HEADER_ENTER_REGISTER_LOGIN = 'web-header.enter-register-login',
  WEB_HEADER_ENTER_SEARCH = 'web-header.enter-search',
  WEB_HEADER_ENTER_WEB_PGC_ARTICLE_LIST = 'web-header.enter-web-pgc-article-list',
  WEB_HEADER_ENTER_WEB_EXPLORE = 'web-header.enter-web-explore',
  WEB_HEADER_ENTER_POPSELECT = 'web-header.enter-popselect',
  WEB_HEADER_CATEGORY_BAR_ENTER_WEB_CATEGORY = 'web-header.category-bar.enter-web-category',
  // header.menu
  WEB_HEADER_MENU_ENTER_EDITOR = 'web-header.menu.enter-editor',
  WEB_HEADER_MENU_ENTER_USER_PROFILE = 'web-header.menu.enter-user-profile',
  WEB_HEADER_MENU_LOGOUT = 'web-header.menu.logout',
  WEB_HEADER_MENU_ENTER_CREATOR_DASHBOARD = 'web-header.menu.enter-creator-dashboard',
  WEB_HEADER_MENU_ENTER_SETTING = 'web-header.menu.enter.settings',
  WEB_HEADER_ENTER_EC_PROMOTION_BANNER = 'web-header.enter-ec-promotion-banner',
}
