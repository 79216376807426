import { PageName } from '@app-core/eventTracking/PageName'
import { getEventTracker } from '@libs/eventTracking'
import {
  getNextPagePropertyFor,
  getCurrentPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/tracking_events/events/web-header/web-header.category-bar.enter-web-category.md)
 */
export function trackHeaderCategoryBarEnterWebCategoryEvent({ toPageUrl, category }) {
  getEventTracker().track({
    name: WebEventNames.WEB_HEADER_CATEGORY_BAR_ENTER_WEB_CATEGORY,
    properties: {
      currentPage: getCurrentPagePropertyFor(''),
      toPage: getNextPagePropertyFor({
        pageName: PageName.WEB_CATEGORY,
        pageUrl: toPageUrl,
      }),
      category,
    },
  })
}
