import { getEventTracker } from '@libs/eventTracking'
import {
  getNextPagePropertyFor,
  getCurrentPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'
import { paths } from '@routes/header-paths'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/tracking_events/events/web-header/web-header.enter-search.md#web-headerenter-search)
 */
export function trackHeaderEnterSearchEvent() {
  getEventTracker().track({
    name: WebEventNames.WEB_HEADER_ENTER_SEARCH,
    properties: {
      currentPage: getCurrentPagePropertyFor(''),
      toPage: getNextPagePropertyFor({
        pageName: 'web-search',
        pageUrl: window.location.origin + paths.search,
      }),
    },
  })
}
