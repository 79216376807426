// https://www.figma.com/file/kg1wCHa2ZWS7mneaXyqyKc/Web-Components-2.0?node-id=74%3A66
import { FC } from 'react'
import tw, { styled } from 'twin.macro'

export enum BadgeVariant {
  default = 'default',
  primary = 'primary',
}

export type Props = {
  variant?: BadgeVariant
}

const baseStyle = tw`rounded-3 px-2 py-1 text-small-d leading-small-d font-semibold`
const defaultStyle = tw`bg-gray-30 text-gray-800`
const primaryStyle = tw`bg-primary-normal text-white`

const Badge = styled.span<Props>(({ variant = BadgeVariant.default }) => [
  baseStyle,
  variant === BadgeVariant.default && defaultStyle,
  variant === BadgeVariant.primary && primaryStyle,
])

export default Badge
export const TypeOverwriteForStorybook: FC<Props> = Badge
