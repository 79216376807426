import { getEventTracker } from '@libs/eventTracking'
import {
  getNextPagePropertyFor,
  getCurrentPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'
import { paths } from '@routes/header-paths'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/tracking_events/events/web-header/web-header.menu.enter-user-profile.md)
 */
export function trackHeaderMenuEnterUserProfileEvent(toPageMidOrMe) {
  getEventTracker().track({
    name: WebEventNames.WEB_HEADER_MENU_ENTER_USER_PROFILE,
    properties: {
      currentPage: getCurrentPagePropertyFor(''),
      toPage: getNextPagePropertyFor({
        pageName: 'user-profile',
        pageUrl: `${window.location.origin}${
          toPageMidOrMe === 'mid' ? paths.userProfile : paths.user
        }`,
      }),
    },
  })
}
