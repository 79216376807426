import { useEffect, useState } from 'react'
import { theme } from 'twin.macro'

export enum ScreenSize {
  mobile = 'mobile',
  desktop = 'desktop',
}

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState('')
  useEffect(() => {
    if (window.matchMedia(`(max-width: ${theme`screens.md`})`).matches) {
      setScreenSize(ScreenSize.mobile)
    } else {
      setScreenSize(ScreenSize.desktop)
    }
  }, [])
  return screenSize
}

export default useScreenSize
