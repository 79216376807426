import React, { PureComponent } from 'react'
import styles from './FollowBtn.scss'

export default class FollowBtn extends PureComponent {
  render() {
    const { follow, onClick } = this.props
    let text = follow.get('isFollowed') ? '追蹤中' : '＋追蹤'
    // let mid = follow.get('userInfo').get('mid')
    return (
      <div className="btn" data-followed={follow.get('isFollowed') || 0} onClick={onClick}>
        <style jsx>{styles}</style>
        {text}
      </div>
    )
  }
}
