import css from 'styled-jsx/css';

export default css`.item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 15px 10px;
  background-color: #FFF;
  margin-bottom: 4px;
  border-radius: 8px; }
  .item:hover {
    cursor: pointer;
    background-color: rgba(102, 102, 255, 0.16) !important; }
  .item[check="-1"] {
    background-color: rgba(102, 102, 255, 0.08); }
  .item[type] .userImg {
    position: relative; }
    .item[type] .userImg:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: -4px;
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat; }
  .item[type="official"] .userImg:after {
    background-image: url(./statics/images/notify/official@3x.png); }
  .item[type="like"] .userImg:after,
  .item[type="replyLike"] .userImg:after {
    background-image: url(./statics/images/notify/like@3x.png); }
  .item[type="saved"] .userImg:after {
    background-image: url(./statics/images/notify/save@3x.png); }
  .item[type="reply"] .userImg:after,
  .item[type="replyb"] .userImg:after,
  .item[type="replyImg"] .userImg:after,
  .item[type="replybImg"] .userImg:after,
  .item[type="replyAll"] .userImg:after {
    background-image: url(./statics/images/notify/comment@3x.png); }
  .item[type="follow"] .userImg:after {
    background-image: url(./statics/images/notify/follow.png); }
  .item .userImg, .item .notifyImg {
    width: 52px;
    height: 52px; }
  .item .userImg img {
    border-radius: 50%; }
  .item .notifyImg {
    margin-left: 10px; }
    .item .notifyImg img {
      border-radius: 4px; }
  .item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .item .content {
    flex: 1;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    .item .content .title, .item .content .text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      font-size: .9em;
      line-height: 1.5em; }
    .item .content .title {
      -webkit-line-clamp: 1;
      font-weight: bold; }
    .item .content .text {
      -webkit-line-clamp: 2;
      margin-bottom: 8px;
      word-break: break-all; }
    .item .content .title + .text {
      -webkit-line-clamp: 2; }
    .item .content .date {
      color: #999;
      font-size: .7em; }
  .item .icon {
    width: 1em;
    height: 1em;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 10px; }
`