import React, { PureComponent } from 'react'
import { Waypoint } from 'react-waypoint'
import { compose, withTranslation } from '@hocs'
import { trackNotificationViewEvent } from '@libs/eventTracking/notification'
import Loading from '@components/Loading'
import NotifyItem from './NotifyItem'
import styles from './Notify.scss'

class Notify extends PureComponent {
  componentDidMount() {
    if (this.props.isApp) return

    const fromPageName = () => {
      switch (window.location.pathname) {
        case '/featured':
          return 'web-pgc'
        case '/':
        case '/explore/featured':
          return 'web-explore'
      }
    }

    const fromPage = {
      pageName: fromPageName(),
      pageUrl: window.location.href,
    }

    trackNotificationViewEvent({
      fromPage,
      currentPage: {
        pageName: 'notification',
      },
    })
  }

  render() {
    const { userNotifyList, systemNotifyList, onLoadMore, t } = this.props
    const userNotifyListData = userNotifyList.get('data')
    const systemNotifyListData = systemNotifyList.get('data')
    return (
      <div className="notify content__block">
        <style jsx>{styles}</style>
        {systemNotifyListData.size > 0 && <div className="title">{t('proclamation')}</div>}
        {systemNotifyListData.map((item) => (
          <NotifyItem key={item.get('id')} item={item} />
        ))}
        {userNotifyListData.size > 0 && <div className="title">{t('notification')}</div>}
        {userNotifyListData.map((item) => (
          <NotifyItem key={item.get('id')} item={item} />
        ))}
        {userNotifyList.get('isLoading') && !userNotifyList.get('done') && <Loading />}
        {userNotifyList.get('done') && <div className="loadStop">到底囉</div>}
        <Waypoint onEnter={onLoadMore} />
      </div>
    )
  }
}

export { Notify }
export default compose(withTranslation(['notify']))(Notify)
