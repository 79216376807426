export enum PageName {
  PROFILE = 'user-profile',
  SEARCH = 'search',
  WEB_EXPLORE = 'web-explore',
  WEB_HOMEPAGE = 'web-homepage',
  WEB_CATEGORY = 'web-category',
  EDITOR = 'editor',
  USER_PROFILE = 'user-profile',
  ARTICLE = 'article',
  PUSH_NOTIFICATION = 'push-notification',
  APP_EXPLORE = 'app-explore',
  APP_SEARRCH = 'app-search',
  FAN_LIST = 'fan-list',
  FOLLOWING_LIST = 'following-list',
}
