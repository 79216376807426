import tw, { styled } from 'twin.macro'

const IconButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 1em;
  position: relative;

  ${tw`flex justify-center items-center`}

  &:hover {
    ${tw`bg-gray-30`};
  }
`

export default IconButton
