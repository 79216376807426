/**
 * 放置各種表示特殊 UI 操作事件的 CustomEvent 定義和 dispatcher
 */

export * from './reply'

export enum HeaderEvents {
  ENTER_CREATOR_RECRUITMENT = 'header.enter-creator-recruitment',
  ENTER_TW_POPDAILY = 'header.enter-tw-popdaily',
  ENTER_REGISTER_LOGIN = 'header.enter-register-login',
  ENTER_SEARCH = 'header.enter-search',
  ENTER_WEB_EXPLORE = 'header.enter-web-explore',
  ENTER_TAB = 'header.enter-tab',
}

export enum HeaderMenusEvents {
  ENTER_EDITOR = 'header.menu.enter-editor',
  ENTER_USER_PROFILE = 'header.menu.enter-user-profile',
  LOGOUT = 'header.menu.logout',
  WEB_HEADER_MENU_ENTER_CREATOR_DASHBOARD = 'web-header.menu.enter-creator-dashboard',
  ENTER_SETTING = 'header.menu.enter-setting',
}

export enum OptionMenuUIEvents {
  COPY_LINK = 'copy.link',
  SHARE = 'share',
  REPORT = 'report',
  DELETE = 'delete',
}

export enum NavigationEvents {
  ENTER_EDITOR = 'enter-editor',
  ENTER_PLACE = 'enter-place',
  ENTER_USER_PROFILE = 'enter-user-profile',
  ENTER_READ_MORE_SAME_AUTHOR = 'enter-read-more-same-author',
  ENTER_READ_MORE_SAME_TYPE = 'enter-read-more-same-type',
  ENTER_READ_MORE_RECOMMENDER_ENTER_ARTICLE = 'enter-read-more-recommender-enter-article',
}

export enum FollowUIEvents {
  CLICK_FOLLOW = 'click-follow',
}

export enum LikeUIEvents {
  READ_MORE_SANE_AUTHOR_CLICK_LIKE = 'read_more_same_author_click-like',
  READ_MORE_SANE_TYPE_CLICK_LIKE = 'read_more_same_type_click-like',
}

export enum PazzoUIEvents {
  OPEN_PAZZO_ITEM_MENU = 'open_pazzo_item_menu',
}

export function dispatchCustomEvent<T = any>(eventType: string, detail: T) {
  window.dispatchEvent(new CustomEvent(eventType, { detail }))
}

export function dispatchEvent(eventType: string) {
  window.dispatchEvent(new Event(eventType))
}
