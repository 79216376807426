import { popSelectUrl } from '@libs/appUrl'

export const paths = {
  index: '/',
  search: '/search',
  map: '/map',
  notifications: '/notifications',
  login: '/login',
  user: '/user/me',
  userProfile: '/user/:mid',
  setting: '/settings',
  report: '/settings/report',
  about: '/about/us',
  privacy: '/policy/privacy',
  contact: '/about/contact',
  copyright: '/about/copyright',
  hiring: 'https://www.104.com.tw/company/1a2x6biwa2',
  poper: '/poper',
  creatorDashboard: '/creator/dashboard',
  newArticle: '/articles/new',
  pgc: '/featured',
  explore: '/explore/featured',
}

export const links = {
  malaysiaMandarin: 'https://popdaily.com/my/',
  appDeepLink: 'https://bit.ly/2ODTU9Z',
  legacyPopSelect: 'https://www.popdaily.com.tw/search/%23%E9%9D%88%E6%84%9F%E6%97%A5%E6%97%A5%E6%9D%91?filter=ALL&sort=RELEVANT',
  popSelect: 'https://www.popdaily.com.tw/search/%23%E9%9D%88%E6%84%9F%E6%97%A5%E6%97%A5%E6%9D%91?filter=ALL&sort=RELEVANT',
}

export function getUserProfilePath(mid?: string) {
  if (!mid) return paths.user
  return paths.userProfile.replace(':mid', mid)
}
