import tw, { styled, theme } from 'twin.macro'

import { ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@components/Icons'

export const DropdownContent = styled.div`
  ${tw`w-full`}
  > ul:last-child {
    border: none;
  }
`
export const DropdownItemChevronIconRight = styled(ChevronRightIcon)`
  width: 16px;
  ${tw`text-gray-400`};
`

export const DropdownItemChevronIconDown = styled(ChevronDownIcon)`
  width: 16px;
  ${tw`text-gray-400`};
`

export const DropdownItemChevronIconTop = styled(ChevronUpIcon)`
  width: 16px;
  ${tw`text-gray-400`};
`

export const DropdownItemTitle = styled.div<{
  active?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  ${(props) => (props.active ? tw`text-primary-indigo` : tw`text-gray-800`)};
  padding: 8px 10px 8px 22px;
  height: 100%;
  @media (max-width: ${theme`screens.md`}) {
    height: 60px;
    padding-right: 0;
  }
  > svg {
    path {
      ${(props) =>
        props.active ? tw`fill-current text-primary-indigo` : tw`fill-current text-gray-800`};
    }
  }
  > .icon {
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
  }
  > p {
    flex: 1;
  }
`

export const DropdownWrapper = styled.ul`
  border-bottom: 1px solid #e6e6e6;
  padding: 0 12px;
  @media (max-width: ${theme`screens.md`}) {
    padding: 0;
  }
  ${tw`w-full`}
`

export const DropdownSubMenu = styled.div<{
  position: {
    top: number
    right: number
  }
}>`
  position: absolute;
  top: ${({ position }) => `${position.top}px`};
  right: ${({ position }) => `${position.right}px`};
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  ${DropdownItemTitle} {
    padding-left: 0;
  }
`

export const DropdownItem = styled.li`
  display: block;
  cursor: pointer;
  overflow: hidden;

  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 12px;
  transition: none;
  font-weight: normal;
  font-size: 14px;
  padding: 0 20px;
  transition: 300ms;
  height: 60px;

  @media (min-width: ${theme`screens.md`}) {
    height: 36px;
    margin: 12px 0;

    &:hover {
      ${tw`bg-primary-indigo bg-opacity-8`};
    }
  }

  ${DropdownWrapper} {
    ${DropdownItemTitle} {
      padding-left: 0;
    }
  }
`
