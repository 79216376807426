import { getEventTracker } from '@libs/eventTracking'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'
import { WebEventNames } from './common'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/develop/tracking_events/events/web-header/web-header.menu.logout.md)
 */
export function trackHeaderMenuLogoutEvent() {
  getEventTracker().track({
    name: WebEventNames.WEB_HEADER_MENU_LOGOUT,
    properties: {
      currentPage: getCurrentPagePropertyFor(''),
    },
  })
}
