import React, { useEffect } from 'react'
import getDisplayName from '@hocs/getDisplayName'
import { HeaderEvents, HeaderMenusEvents } from '@libs/customEvents'
import {
  trackHeaderEnterCreatorRecruitmentEvent,
  trackHeaderMenuEnterEditorEvent,
  trackHeaderEnterTwPopdailyEvent,
  trackHeaderEnterRegisterLoginEvent,
  trackHeaderEnterSearchEvent,
  trackHeaderEnterWebPgcArticleListEvent,
  trackHeaderEnterWebExploreEvent,
  trackHeaderMenuEnterUserProfileEvent,
  trackHeaderMenuLogoutEvent,
  trackHeaderEnterCreatorDashboardEvent,
  trackHeaderMenuEnterEditorSetting,
} from '@libs/eventTracking/header'

export function withHeaderEventTracking(WrappedComponent: React.ComponentType) {
  function WithHeaderEventTracking(props) {
    useTrackEnterCreatorRecruitment()
    useTrackMenuEnterEditor()
    useTrackEnterTwPopdaily()
    useTrackEnterRegisterLogin()
    useTrackEnterSearch()
    useTrackEnterTab()
    useTrackMenuEnterUserProfile()
    useTrackMenuLogout()
    useTrackMenuEnterCreatorDashboard()
    useTrackMenuEnterSetting()
    return <WrappedComponent {...props} />
  }
  WithHeaderEventTracking.displayName = `WithHeaderEventTracking(${getDisplayName(
    WrappedComponent,
  )})`

  return WithHeaderEventTracking
}
/**
 * Header
 */
export function useTrackEnterCreatorRecruitment() {
  useEffect(() => {
    const handler = () => {
      trackHeaderEnterCreatorRecruitmentEvent()
    }
    window.addEventListener(HeaderEvents.ENTER_CREATOR_RECRUITMENT, handler)
    return () => {
      window.removeEventListener(HeaderEvents.ENTER_CREATOR_RECRUITMENT, handler)
    }
  }, [])
}

export function useTrackEnterTwPopdaily() {
  useEffect(() => {
    const handler = () => {
      trackHeaderEnterTwPopdailyEvent()
    }
    window.addEventListener(HeaderEvents.ENTER_TW_POPDAILY, handler)
    return () => {
      window.removeEventListener(HeaderEvents.ENTER_TW_POPDAILY, handler)
    }
  }, [])
}

export function useTrackEnterRegisterLogin() {
  useEffect(() => {
    const handler = () => {
      trackHeaderEnterRegisterLoginEvent()
    }
    window.addEventListener(HeaderEvents.ENTER_REGISTER_LOGIN, handler)
    return () => {
      window.removeEventListener(HeaderEvents.ENTER_REGISTER_LOGIN, handler)
    }
  }, [])
}

export function useTrackEnterSearch() {
  useEffect(() => {
    const handler = () => {
      trackHeaderEnterSearchEvent()
    }
    window.addEventListener(HeaderEvents.ENTER_SEARCH, handler)
    return () => {
      window.removeEventListener(HeaderEvents.ENTER_SEARCH, handler)
    }
  }, [])
}

export function useTrackEnterTab() {
  useEffect(() => {
    const handler = ({ detail: { tabType } }: CustomEventInit<{ tabType: string }>) => {
      if (tabType === 'website') {
        trackHeaderEnterWebPgcArticleListEvent()
      }
      if (tabType === 'explore') {
        trackHeaderEnterWebExploreEvent()
      }
    }
    window.addEventListener(HeaderEvents.ENTER_TAB, handler)
    return () => {
      window.removeEventListener(HeaderEvents.ENTER_TAB, handler)
    }
  }, [])
}

/**
 * Header.menu
 */
export function useTrackMenuEnterEditor() {
  useEffect(() => {
    const handler = () => {
      trackHeaderMenuEnterEditorEvent()
    }
    window.addEventListener(HeaderMenusEvents.ENTER_EDITOR, handler)
    return () => {
      window.removeEventListener(HeaderMenusEvents.ENTER_EDITOR, handler)
    }
  }, [])
}

export function useTrackMenuEnterUserProfile() {
  useEffect(() => {
    const handler = ({ detail: { toPageMidOrMe } }: CustomEventInit<{ toPageMidOrMe: string }>) => {
      trackHeaderMenuEnterUserProfileEvent(toPageMidOrMe)
    }
    window.addEventListener(HeaderMenusEvents.ENTER_USER_PROFILE, handler)
    return () => {
      window.removeEventListener(HeaderMenusEvents.ENTER_USER_PROFILE, handler)
    }
  }, [])
}

export function useTrackMenuLogout() {
  useEffect(() => {
    const handler = () => {
      trackHeaderMenuLogoutEvent()
    }
    window.addEventListener(HeaderMenusEvents.LOGOUT, handler)
    return () => {
      window.removeEventListener(HeaderMenusEvents.LOGOUT, handler)
    }
  }, [])
}

export function useTrackMenuEnterCreatorDashboard() {
  useEffect(() => {
    const handler = () => {
      trackHeaderEnterCreatorDashboardEvent()
    }
    window.addEventListener(HeaderMenusEvents.WEB_HEADER_MENU_ENTER_CREATOR_DASHBOARD, handler)
    return () => {
      window.removeEventListener(HeaderMenusEvents.WEB_HEADER_MENU_ENTER_CREATOR_DASHBOARD, handler)
    }
  }, [])
}

export function useTrackMenuEnterSetting() {
  useEffect(() => {
    const handler = () => {
      trackHeaderMenuEnterEditorSetting()
    }
    window.addEventListener(HeaderMenusEvents.ENTER_SETTING, handler)
    return () => {
      window.removeEventListener(HeaderMenusEvents.ENTER_SETTING, handler)
    }
  }, [])
}
