import { useState } from 'react'
import AllIcon from '@components/Icons/AllIcon'
import IconButton from '../IconButton'
import HeaderMenu, { UserStatus } from '../../HeaderMenu'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { styled } from 'twin.macro'

const Container = styled.div`
  position: relative;
`

function AllIconButton() {
  const [visible, setVisible] = useState(false)

  const onClick = () => setVisible((value) => !value)
  const onClose = () => setVisible(false)

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Container>
        <IconButton onClick={onClick}>
          <AllIcon />
        </IconButton>
        <HeaderMenu open={visible} userStatus={UserStatus.guest} handleClose={onClose} />
      </Container>
    </ClickAwayListener>
  )
}

export default AllIconButton
