import tw, { styled, theme } from 'twin.macro'
import Button, { ButtonVariant } from '@components/DesignSystem/Button'
import BagIcon from '@components/Icons/BagIcon'
import { links } from '@routes/header-paths'
import { useTranslation } from '@i18n'
import getConfig from 'next/config'
import { openPost } from '@libs/appUrl'
import { trackWebHeaderEnterPopselect } from '@libs/eventTracking/header/web-header.enter-popselect'

const {
  publicRuntimeConfig: { popSelectLinkEnabled },
} = getConfig()

const StyledButton = styled(Button)`
  padding: 0 12px;
  margin-left: 12px;

  @media (min-width: ${theme`screens.md`}) {
    padding: 0 20px;
  }
`

const MobileText = styled.span`
  ${tw`inline md:hidden`}
`
const DesktopText = styled.span`
  ${tw`hidden md:inline`}
`
const PopSelectLink = (props) => {
  const { isArticlePage } = props
  const { t } = useTranslation('header')

  const _onClickOpenLink = () => {
    const link = popSelectLinkEnabled ? links.popSelect : links.legacyPopSelect

    const fromPageName = () => {
      if (isArticlePage) {
        return 'article'
      }
      switch (window.location.pathname) {
        case '/featured':
          return 'web-pgc'
        case '/':
        case '/explore/featured':
          return 'web-homepage'
        case '/search':
          return 'web-search'
      }
    }

    const payload = {
      currentPage: {
        pageName: fromPageName(),
        pageUrl: window.location.href,
      },
      toPage: {
        pageUrl: link,
        pageName: 'popselect',
      },
    }

    trackWebHeaderEnterPopselect(payload)
    openPost(link)
  }

  return (
    <StyledButton
      as="a"
      target="_blank"
      variant={ButtonVariant.primary}
      onClick={() => _onClickOpenLink()}
    >
      <MobileText>{t('popSelect.mobile')}</MobileText>
      <DesktopText>{t('popSelect.desktop')}</DesktopText>
    </StyledButton>
  )
}

export default PopSelectLink
