import tw, { styled, theme } from 'twin.macro'
import { DropdownItem } from './Dropdown.styled'
import { HeaderMenusEvents, dispatchCustomEvent } from '@libs/customEvents'
import getConfig from 'next/config'
import { paths } from '@routes/header-paths'
import { getUserProfilePath } from '@routes/header-paths'
import { useTranslation } from '@i18n'

const {
  publicRuntimeConfig: { popSelectEnabled },
} = getConfig()

export type UserInfo = {
  userName?: string
  avatar?: string
  mid?: string
}

export enum UserStatus {
  guest = 'guest',
  member = 'member',
  creator = 'creator',
}

const StyledDropdownItem = styled(DropdownItem)`
  padding: 0;
  @media (min-width: ${theme`screens.md`}) {
    padding-left: 12px;
    &:hover {
      ${tw`bg-transparent`};
    }
  }
`

const Container = styled.a`
  ${tw`flex items-center w-full h-full`};

  > p {
    width: 220px;
    text-overflow: ellipsis;
  }

  > img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
  }

  @media (min-width: ${theme`screens.md`}) {
    > p {
      width: calc(100% - 40px);
    }
  }
`

type Props = UserInfo & {
  userStatus: UserStatus
}

const UserItem = (props: Props) => {
  const { t } = useTranslation('header')
  const { userName, avatar, mid, userStatus } = props
  if (!popSelectEnabled && userStatus == UserStatus.guest) return null

  const renderChildren = () => {
    if (userStatus == UserStatus.guest) {
      return (
        <Container href={paths.login}>
          <img src={avatar} />
          <p>{t('register/login')}</p>
        </Container>
      )
    }

    return (
      <Container
        href="/user/me"
        onClick={() =>
          dispatchCustomEvent(HeaderMenusEvents.ENTER_USER_PROFILE, { toPageMidOrMe: 'mid' })
        }
      >
        <img src={avatar} />
        <p>{userName}</p>
      </Container>
    )
  }

  return <StyledDropdownItem>{renderChildren()}</StyledDropdownItem>
}
export default UserItem
