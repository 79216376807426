import { getEventTracker } from '@libs/eventTracking'
import { WebEventNames } from '@libs/eventTracking/notification/common'
import {
  getCurrentPagePropertyFor,
  getNextPagePropertyFor,
} from '@libs/eventTracking/eventPropertyUtils'

export function trackNotificationPrivateEnterEvent(input) {
  const { pageName, pageUrl } = input.currentPage
  getEventTracker().track({
    name: WebEventNames.NOTIFICATION_PRIVATE_ENTER_NOTIFICATION,
    properties: {
      currentPage: getCurrentPagePropertyFor(pageName, pageUrl, 'private'),
      toPage: getNextPagePropertyFor(input.toPage),
      actionTarget: input.actionTarget,
    },
  })
}
