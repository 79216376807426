import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUserNotifyList, getSystemNotifyList, clearNotify } from '@app-core/notify/actions'

type State = {
  notify: {
    get: (t: string) => any
  }
}
const useNotificationData = ({ open }) => {
  const state = useSelector((state: State) => ({
    notify: state.notify,
  }))
  const dispatch = useDispatch()
  const userNotifyList = state.notify.get('userNotifyList')
  const systemNotifyList = state.notify.get('systemNotifyList')
  const userNotifyListSize = userNotifyList.get('data').size
  const systemNotifyListSize = systemNotifyList.get('data').size
  const fetchUserNotifyList = useCallback(
    (page = 9999999999999) => dispatch(getUserNotifyList({ page })),
    [dispatch],
  )
  const fetchSystemNotifyList = useCallback(() => dispatch(getSystemNotifyList()), [dispatch])
  const callClearNotify = useCallback(() => dispatch(clearNotify({})), [dispatch])
  useEffect(() => {
    if (open) callClearNotify()
  }, [open, callClearNotify])
  useEffect(() => {
    fetchUserNotifyList()
    fetchSystemNotifyList()
  }, [fetchUserNotifyList, fetchSystemNotifyList])
  const loadMore = () => {
    if (userNotifyListSize === 0) return
    fetchUserNotifyList(userNotifyList.getIn(['data']).takeLast(1).getIn([0, 't']))
  }
  return {
    userNotifyList,
    systemNotifyList,
    userNotifyListSize,
    systemNotifyListSize,
    loadMore,
  }
}

export default useNotificationData
