import { useMemo, useState } from 'react'
import tw, { styled } from 'twin.macro'
import NotificationIcon from '@components/Icons/NotificationIcon'
import NotificationOnIcon from '@components/Icons/NotificationOnIcon'
import IconButton from '../IconButton'
import NotifyPopper from './NotifyPopper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import getConfig from 'next/config'
import { useSelector } from 'react-redux'

const {
  publicRuntimeConfig: { popSelectEnabled },
} = getConfig()

const Container = styled.div`
  position: relative;
  ${popSelectEnabled && tw`hidden md:block`};
`

const ColoredNotificationOnIcon = styled(NotificationOnIcon)`
  ${tw`text-primary-indigo`}
`

export const NotifyCount = styled.div`
  ${tw`bg-primary-indigo absolute text-primary-light rounded-full`}
  font-size: 20px;
  line-height: 1.2;
  top: 5.635px;
  left: 22px;
  padding: 4px 8px;
  transform: scale(0.5); // hack for too small font-size
  transform-origin: top left;
`

function NotificationIconButton() {
  const [visible, setVisible] = useState(false)
  const notifyCount = useSelector<any, number>((state) =>
    state.notify.getIn(['notifyState', 'newMsg']),
  )

  const onClick = () => setVisible((value) => !value)
  const onClose = () => setVisible(false)

  const icon = useMemo(() => {
    if (notifyCount === 0) return <NotificationIcon />

    return (
      <>
        <ColoredNotificationOnIcon />
        <NotifyCount>{notifyCount > 99 ? '99+' : notifyCount}</NotifyCount>
      </>
    )
  }, [notifyCount])

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Container>
        <IconButton onClick={onClick}>{icon}</IconButton>
        <NotifyPopper open={visible} handleClose={onClose} />
      </Container>
    </ClickAwayListener>
  )
}

export default NotificationIconButton
